<template>
  <!-- 新建banner页面 -->
  <div class="positionT0L0">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 2 }" :wrapper-col="{ span: 10 }">
      <a-form-model-item label="海报名称" prop="name" class="por">
        <a-input v-model="form.name" :maxLength="32" placeholder="请输入海报名称">
          <div slot="addonAfter">{{ form.name.length }}/32</div>
        </a-input>
      </a-form-model-item>

      <a-form-model-item label="海报图片" prop="imageUrl">
        <div class="poster">
          <UpdateImg @avatarfns="data => form.imageUrl = data[0]" :imageUrlNum="1" :imgUrl="form.imageUrl ? [form.imageUrl] : []" />
          <div>
            <span>应用于邀请好友分享海报</span>
            <span>建议尺寸600px * 860px，JPG.PNG.格式 图片小于500k</span>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>

    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
      <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
    </FooterToolBar>

  </div>
</template>

<script>
import { baseMixin } from '@/store/app-mixin'
import FooterToolBar from '@/components/FooterToolbar'
import moment from "moment"
import UpdateImg from '@/components/file/updataImg'
import { addShopPlaybill, editShopPlaybillList, getOneShopPlaybill } from '@/request/api/indexManage'
export default {
  mixins: [ baseMixin ],
  components: { FooterToolBar, UpdateImg },
  created() {
    const id = +this.$route.params.id
    if (id !== 0) {
      this.getDetail(id)
    }
  },
  data() {
    return {
      goodsDetail: {}, // 商品详情
      keepLoading: false, // 保存的按钮是否加载
      form: {
        id: '',
        name: '',
        imageUrl: '',
        source: 2,
        state: 2
      },
      rules: {
        name: [
          {required: true, message: "输入的内容不能为空", trigger: "blur"},
          {max: 32, message: "长度最长为32个", trigger: "blur"},
        ],
        imageUrl:[
          { required: true, message: "请上传海报图片", trigger: ['blur', 'change']}
        ]
      }
    }
  },
  methods: {
    moment,
    async getDetail(id) {
      const { code, data } = await getOneShopPlaybill({ id: id })
      if (code !== 200) return
      this.form = {
        id: data.id,
        name: data.name,
        imageUrl: data.imageUrl,
        source: 2,
        state: data.state
      }
    },
    // 保存的按钮
    keepClick() {
      this.keepLoading = true
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          !!this.form.id ? await this.edit() : await this.add()
        }
        this.keepLoading = false
      })
    },
    async add() {
      delete this.form.id
      const { code } = await addShopPlaybill(this.form)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.$router.push('/appletsManage/sharePoster')
    },
    async edit() {
      const { code } = await editShopPlaybillList(this.form)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.$router.push('/appletsManage/sharePoster')
    },
  }
}
</script>

<style lang="less" scoped>
.poster {
  display: flex;
  align-items: center;
  span {
    display: block;
    margin-left: 20px;
    font-weight: bold;
    line-height: 30px;
  }
}
</style>
